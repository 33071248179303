import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_el from "./translations/el.json";
import common_en from "./translations/en.json";

i18n.use(LanguageDetector).init({
    resources: {
        el: {
            common: common_el
        },
        en: {
            common: common_en
        }
    },
    lng: 'el',
    fallbackLng: "el",
    debug: false,
    ns: ["common"],
    defaultNS: "common",
    keySeparator: '.',
    objectNotation: true,
    interpolation: {
        formatSeparator: ","
    },
    react: {
        useSuspense: true
    }
});

export default i18n;
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import registerServiceWorker from "./registerServiceWorker";
// import { unregister } from './registerServiceWorker';
import { store } from "./redux/storeConfig/store";
// import ReduxToastr from 'react-redux-toastr'
import Spinner from "./components/spinner/spinner";
import "font-awesome/css/font-awesome.min.css";
import "./index.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const LazyApp = lazy(() => import("./app/app"));

//***************************** SOLVING Webpack Code Splitting 'Loading chunk failed' error wrong file path ********************** */
//https://stackoverflow.com/questions/53704950/webpack-code-splitting-loading-chunk-failed-error-wrong-file-path
window.addEventListener('error', e => {
   // prompt user to confirm refresh
   if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
   }
});

ReactDOM.render(
   <Provider store={store}>
      <Suspense fallback={<Spinner />}>
         <I18nextProvider i18n={i18n}>
            <LazyApp />
         </I18nextProvider>
         {/* <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
         /> */}
      </Suspense>
   </Provider>,
   document.getElementById("root")
);


// registerServiceWorker();
// unregister();

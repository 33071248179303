import React from 'react';
import { ClipLoader } from 'react-spinners';

class SpinnerComponent extends React.Component {
    state = {
		size: this.props.size,
        loading: true
    };
	render() {
		const pt = (this.state.size === 'sm') ? 'pt-2' : '';
		const size = (this.state.size === 'sm') ? 30 : 60;
		return (
			<div className={`sweet-loading ${pt}`}>
				<ClipLoader
					className="clip-loader"
					sizeUnit={"px"}
					size={size}
					color={'#fab414'}
					loading={this.state.loading}
				/>
			</div> 
		)
	}
}

export default SpinnerComponent;